@import '~mor-color/theme/theme.less';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    // padding: 0 16px;
}
.header--flex-start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    // border-bottom: 1px solid transparent;
}
.header-block_function {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid @border-divider;
    padding-right: 12px;
}
.header-block_user {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 10px;
    > span {
        margin-left: 5px;
        font-weight: bold;
    }
}
.header__logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 255px;
    height: 100%;
    // margin-left: -16px;
    padding-left: 24px;
    background-color: #002140;
    border-bottom: 1px solid transparent;
    &.header__logo--collapsed {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
        padding-left: 0;
        overflow: hidden;
        > span {
            display: none;
        }
        > .header-block_icon {
            margin: 0;
        }
    }
}
.header-block_icon {
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 5px;
}
.header__text {
    color: @text-primary;
    font-weight: bold;
    font-size: 16px;
}
.header-block_trigger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    cursor: pointer;
}
.header-block_user__container {
    display: flex;
    align-items: center;
}
.header--title {
    color: #fff;
    font-size: 20px;
    margin-left: 5px;
    font-weight: bold;
}