@import '~mor-color/theme/theme.less';

.layout-aside {
    box-sizing: border-box;
    border-right: 1px solid @border-divider;
    position: relative;
    height: calc(100vh - 50px);
    // overflow: hidden;
    background-color: #001529;
    // overflow-x: hidden;
    // overflow-y: auto;
    // &::-webkit-scrollbar{
    //     display:none;/*隐藏滚动条*/
    // }
    .menu__container {
        > h3 {
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-left: 15px;
            font-weight: 700;
            font-size: 14px;
        }
    }
}
.layout-aside_collapsed {
    width: 100%;
    .header-text { 
        display: none;
    }
    .menu__container {
        > h3 {
            height: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-left: 15px;
            font-weight: 700;
        }
    }
}
.menu__container {
   height: auto;
}
