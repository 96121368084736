@import '~mor-color/theme/theme.less';

.screen {
    width: 30px;
    height: 30px;
    margin: 0 1px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    transition: .35s;
    &:hover {
        box-shadow: @shadow;
    }
}
