@import '~mor-color/theme/theme.less';
.header {
    width: 100%;
    height: 50px;
}

.layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}

.side-layout {
    flex-direction: row;
}

.main {
    width: 100%;
    background-color: var(--theme-bg-secondary);;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    background: #f0f2f5;
    position: relative;
}

.content-header {
    margin: 10px 10px 0px;
    background: @bg-block;
    box-sizing: border-box;
    padding: 10px 10px 0px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
}

.content-layout {
    width: auto;
    height: calc(100% - 52px);
    overflow-y: auto;
    &::-webkit-scrollbar{
        display:none;
    }
    display: flex;
    flex-direction: column;
    > .content-main {
        flex: 1 1;
        overflow: hidden;
    }
}

.content-main {
    margin: 10px;
    box-sizing: border-box;
    padding: 10px;
    background: @bg-block;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
}
.content-footer {
    box-sizing: border-box;
    padding: 10px;
    padding-top: 0;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 -1px 3px rgba(0,0,0,.1);
    z-index: 10;
}