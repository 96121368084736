@import '~mor-color/theme/theme.less';

.container {
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid ;
    border-bottom: 1px solid @border-divider;
    > span {
        font-size: 12px;
        color: @text-disabled;
        &:last-child {
            color: rgba(0,0,0,.65);
        }
        > i {
            margin: 0 6px;
        }
    }
}