@import '~mor-color/theme/theme.less';
.main {
    box-sizing: border-box;
    height: calc(100vh - 133px);
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar{
        display:none;/*隐藏滚动条*/
    }
    position: relative;
    background: @bg-block;
}