@import '~mor-color/theme/theme.less';

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url('./../../images/login-wrap-background.jpg') no-repeat center /
        cover;
}
.login-content {
    position: relative;
    width: 750px;
    height: 390px;
    background-color: #e9e9e9;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    > div {
        position: absolute;
        width: 50%;
        height: 100%;
        transition: .35s;
        &:nth-child(1) {
            left: 0;
        }
        &:nth-child(2) {
            left: 50%;
        }
    }
}
.branch__title {
    margin-bottom: 20px;
    font-size: 20px;
}
.container {
    > div {
        position: absolute;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 375px;
        height: 420px;
        transition: .6s;
    }
}
.container--show {
    transform: translate(100%);
}
.dialog {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background: url('./../../images/login-wrap-background.jpg') no-repeat center /
        cover fixed;

}
.dialog--show {
    left: 0 !important;
}
.branch {
    z-index: -1;
    opacity: 0;
    &.branch--show {
        z-index: 5;
        display: flex;
        opacity: 1;
        animation: show 0.6s;
    }
}
.login {
    z-index: -1;
    opacity: 0;
    &.login--show {
        z-index: 5;
        display: flex;
        opacity: 1;
        animation: show 0.6s;
    }
}


.branch__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-top: 20px;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    background-color: #e2b66d;;
    background-image: linear-gradient(90deg, #e2b66d 0%, #ffd448a6 74% 74%);
    border: 1px solid #e2b66d 50%;
    border-radius: 30px;
    height: 45px;
    cursor: pointer;
    opacity: .7;
    transition: transform 80ms ease-in;
    > span {
        display: inline-block;
        width: 100%;
    }
}
.icon {
    position: absolute;
    top: 30%;
    right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin-left: 10px;
    transition: 0.35s;
    &.icon--up {
        transform: rotate(180deg);
    }
}

.dialog__button {
    width: 60%;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    background-color: #e2b66d;;
    background-image: linear-gradient(90deg, #e2b66d 0%, #ffd448a6 74% 74%);
    border: 0;
    border: 1px solid #e2b66d 50%;
    border-radius: 30px;
    cursor: pointer;
    opacity: .7;
    transition: transform 80ms ease-in;
}

@keyframes show {

    0%,
    49.99% {
        z-index: 1;
        opacity: 0;
    }

    50%,
    100% {
        z-index: 5;
        opacity: 1;
    }
}