@import '~mor-color/theme/theme.less';
.btn {
    width: 100%;
    padding: 10px;
    color: #fff;
    font-size: 16px;
    background-color: #e2b66d;;
    background-image: linear-gradient(90deg, #e2b66d 0%, #ffd448a6 74% 74%);
    border: 0;
    border: 1px solid#e2b66d 50%;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 80ms ease-in;
}
// .btn {
//     position: relative;
//     width: 100%;
//     padding: 10px 20px;
//     overflow: hidden;
//     color: #FF770D;
//     letter-spacing: 2px;
//     text-transform: uppercase;
//     background: transparent;;
//     background-color: rgba(256, 256, 256, .5);
//     border: 0;
//     border-radius: 2px;
//     outline: none;
//     box-shadow: 0 0 1px @primary;
//     cursor: pointer;
//     &:hover {
//         color: #fff;
//         background: #FF770D;
//         border-radius: 5px;
//         box-shadow: 0 0 1px 0 #FF770D,
//             0 0 5px 0 #FF770D,
//             0 0 10px 0 #FF770D,
//             0 0 20px 0 #FF770D;
//         transition: all .3s linear;
//     }
//     > span {
//         position: absolute;
//         &:nth-child(1) {
//             top: 0;
//             left: -100%;
//             width: 100%;
//             height: 2px;
//             background: -webkit-linear-gradient(left, transparent, #FF770D);
//             animation: line1 1s linear infinite;
//         }
//         &:nth-child(2) {
//             top: -100%;
//             right: 0;
//             width: 2px;
//             height: 100%;
//             background: -webkit-linear-gradient(top, transparent, #FF770D);
//             animation: line2 1s 0.25s linear infinite;
//         }
//         &:nth-child(3) {
//             bottom: 0;
//             left: 100%;
//             width: 100%;
//             height: 2px;
//             background: -webkit-linear-gradient(left, #FF770D, transparent);
//             animation: line3 1s 0.75s linear infinite;
//         }
//         &:nth-child(4) {
//             top: 100%;
//             left: 0;
//             width: 2px;
//             height: 100%;
//             background: -webkit-linear-gradient(top, transparent, #FF770D);
//             animation: line4 1s 1s linear infinite;
//         }
//     }
// }

// @keyframes line1 {
//     50%,
//     100% {
//         left: 100%;
//     }
// }

// @keyframes line2 {
//     50%,
//     100% {
//         top: 100%;
//     }
// }

// @keyframes line3 {
//     50%,
//     100% {
//         left: -100%;
//     }
// }

// @keyframes line4 {
//     50%,
//     100% {
//         top: -100%;
//     }
// }