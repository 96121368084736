@import '~mor-color/theme/theme.less';

.avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    > img {
        width: 100%;
        height: 100%;
    }
    &--small {
        width: 30px;
        height: 30px;
    }

    &--large {
        width: 60px;
        height: 60px;
    }
    &--shadow {
        box-shadow: @shadow;
    }
}
