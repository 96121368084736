.upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color .3s;
    > img {
        height: 100%;
        max-width: 100%;
    }

}

.upload--block {
    width: 100%;
    > div {
        width: 100%;
        height: 100%;
    }
}

.upload__container {
    width: 128px;
    height: 128px;
    &.upload--block {
        width: 100%;
    }
    &.upload--inline {
        display: inline-block;
        width: auto;
        height: auto;
    }
}

.spin__container {
    > div {
        height: 100%;
    }
}