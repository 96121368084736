.main {
    overflow: auto;
    &.main--aside {
        width: calc(100vw - 256px);
        &.main--collapsed {
            width: calc(100vw - 80px);
        }
    }
}
.main--content {
    width: 100%;
    min-width: 900px;
}